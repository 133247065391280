export const swiper = () => {
  const firstScreenSlider = document.querySelector(".first-screen-slider");
  if (firstScreenSlider) {
    let swiperZoomAnim = function (i) {
      let el = document.querySelector(
        ".swiper-slide-active .first-screen-slider-item-content"
      );
      console.log(el);
      // if (!el.classList.contains("is-anim")) {
      //   el.classList.add("is-anim");
      // } else {
      //   el.classList.remove("is-anim");
      // }

      // let anim = document.querySelectorAll(".is-anim");
      // for (let i = 0; i < anim.length; i++) {
      //   anim[i].classList.remove("is-anim");
      // }
      // el.classList.add("is-anim");
    };
    const firstScreenSlider = new Swiper(".first-screen-slider", {
      slidesPerView: 1,
      arrows: false,
      // autoplay: {
      //   delay: 4500,
      // },
      // speed: 100,
      // effect: "fade",
      // loop: true,
      // centeredSlides: true,
      effect: "fade",
      autoplay: {
        delay: 5000,
      },
      pagination: {
        clickable: true,
        el: ".swiper-pagination-custom",
      },
      on: {
        init: function (i) {
          // swiperZoomAnim();
        },
        slideChange: function (i) {
          // swiperZoomAnim(i);
        },
      },
    });
  }
  const whyWeList = document.querySelector(".why-we-list");
  if (whyWeList) {
    new Swiper(".why-we-list", {
      slidesPerView: 1,
      arrows: false,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        // when window width is >= 320px
        640: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
        1280: {
          slidesPerView: 4,
        },
        // when window width is >= 640px
        1535: {
          slidesPerView: 5,
        },
      },
    });
  }
  const workStep = document.querySelector(".work_step-list-mobile");
  if (workStep) {
    new Swiper(".work_step-list-mobile", {
      slidesPerView: 1,
      arrows: false,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
      },
    });
  }

  const buildProcessList = document.querySelector(".build_process-list-mobile");
  if (buildProcessList) {
    new Swiper(".build_process-list-mobile", {
      slidesPerView: 1,
      arrows: false,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
      },
    });
  }

  const swiper = document.querySelectorAll(".mySwiper");
  swiper.forEach((item) => {
    const instance_swiper = new Swiper(item, {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: item
          .closest(".mySwiper")
          .querySelector(".clients-swiper-button-next"),
        prevEl: item
          .closest(".mySwiper")
          .querySelector(".clients-swiper-button-prev"),
      },
    });
  });
  const swiper2 = document.querySelectorAll(".mySwiper2");
  swiper2.forEach((item) => {
    const instance_swiper = new Swiper(item, {
      slidesPerView: 1,
      allowTouchMove: false,
      loop: true,
      navigation: {
        nextEl: item
          .closest(".swiper-v")
          .querySelector(".images-swiper-button-next"),
        prevEl: item
          .closest(".swiper-v")
          .querySelector(".images-swiper-button-prev"),
      },
      pagination: {
        el: item
          .closest(".swiper-v")
          .querySelector(".images-swiper-pagination"),
        clickable: true,
      },
    });
  });

  const casesImages = document.querySelector(".cases-single-images");
  if (casesImages) {
    new Swiper(".cases-single-images", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      autoHeight: true,
      loop: true,
      navigation: {
        nextEl: ".images-swiper-button-next",
        prevEl: ".images-swiper-button-prev",
      },
      pagination: {
        el: ".images-swiper-pagination",
        clickable: true,
      },
    });
  }
};
