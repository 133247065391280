import { swiper } from "./modules/swiper.js";
import { faq } from "./modules/faq.js";
import { contactForm } from "./modules/modal.js";
import { burger } from "./modules/burger.js";
import { count } from "./modules/animate-count.js";
document.addEventListener("DOMContentLoaded", function (event) {
  swiper();
  faq();
  contactForm();
  burger();
  count();
});
