export const count = () => {
  const counterContainer = document.querySelector(".counter-container");
  if (counterContainer) {
    jQuery(window).scroll(startCounter);

    function startCounter() {
      let scrollY =
        (window.pageYOffset || document.documentElement.scrollTop) +
        window.innerHeight;
      let divPos = document.querySelector(".counter-container").offsetTop;

      if (scrollY > divPos) {
        jQuery(window).off("scroll", startCounter);
        jQuery(".count").each(function () {
          let $this = jQuery(this);
          jQuery({
            Counter: 0,
          }).animate(
            {
              Counter: $this.text().replace(/,/g, ""),
            },
            {
              duration: 2500,
              easing: "swing",
              step: function () {
                $this.text(commaSeparateNumber(Math.floor(this.Counter)));
              },
              complete: function () {
                $this.text(commaSeparateNumber(this.Counter));
              },
            }
          );
        });

        function commaSeparateNumber(num) {
          return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    }
  }
};
