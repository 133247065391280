export const contactForm = () => {
  const forms = document.querySelectorAll(".wpcf7");
  const header = document.querySelector("#header");
  const body = document.querySelector("body");
  const modal = document.querySelector(".backdrop");
  const modalButtonClose = document.querySelectorAll(".modal-button-close");
  const modalButtons = document.querySelectorAll(".modal-btn");
  modalButtons.forEach((modalButton) => {
    modalButton.addEventListener("click", () => {
      forms.forEach((form) => {
        modal.classList.remove("is-hidden");
        body.classList.add("overflow-hidden");
        modalButtonClose.forEach((e) => {
          e.addEventListener("click", () => {
            modal.classList.add("is-hidden");
            body.classList.remove("overflow-hidden");
            header.classList.remove("relative");
            header.classList.add("fixed");
          });
        });
        let formWrapper = form.closest("#kontakt");
        form.addEventListener(
          "wpcf7mailsent",
          function (event) {
            let wrapper = form.closest(".contact-form-wrapper");
            let thanks = form
              .closest(".contact-form")
              .querySelector(".contact-form-thanks");
            let headerHeight = header.offsetHeight;
            let top = formWrapper.offsetTop - headerHeight - 10;
            $("body,html").animate({ scrollTop: top }, 400);
            wrapper.classList.add("hide");
            thanks.classList.add("show");

            setTimeout(() => {
              wrapper.classList.remove("hide");
              thanks.classList.remove("show");
            }, 8000);
          },
          false
        );
      });
    });
  });
};
