export const burger = () => {
  const btns = document.querySelectorAll(".header-mobile-burger");
  const menus = document.querySelectorAll(".header-menu-mob");
  const page = document.querySelector("#page");
  const languageArrow = document.querySelectorAll(".dropdown-current");
  languageArrow.forEach((arrow) => {
    arrow.addEventListener("click", () => {
      const headerLanguageSwitcher = document.querySelectorAll(
        ".js-language-switcher"
      );
      headerLanguageSwitcher.forEach((e) => {
        e.classList.toggle("language-show");
      });
    });
  });
  btns.forEach((btn) => {
    btn.addEventListener("mouseenter", () => {
      btn.classList.add("hover");
    });
    btn.addEventListener("mouseleave", () => {
      btn.classList.remove("hover");
    });
    btn.addEventListener("click", () => {
      btn.classList.toggle("active");
      btn.classList.remove("hover");
      toggleMenu();
    });
  });

  const toggleMenu = () => {
    menus.forEach((menu) => {
      menu.classList.toggle("scale-y-0");
    });
    page.classList.toggle("active");
    document.querySelector("body").classList.toggle("overflow-hidden");
  };
};
