export const faq = () => {
  $(".single-post_info_faq_question").on("click", function () {
    $(this).toggleClass("active");
    $(this)
      .siblings(".single-post_info_faq_answer")
      .each(function () {
        $(this).slideToggle(300);
      });
    $(this)
      .closest(".single-post_info_faq")
      .siblings(".single-post_info_faq")
      .each(function () {
        $(this).find(".single-post_info_faq_question").removeClass("active");
        $(this).find(".single-post_info_faq_answer").slideUp(300);
      });
  });
  // const triggers = document.querySelectorAll(".single-post_info_faq_question");
  // triggers.forEach((trigger) => {
  //   let triggerSiblings = trigger
  //     .closest(".single-post_info_faq-container")
  //     .querySelectorAll(".single-post_info_faq_question");
  //   let contents = trigger
  //     .closest(".single-post_info_faq-container")
  //     .querySelectorAll(".single-post_info_faq_answer");
  //   trigger.addEventListener("click", (e) => {
  //     triggerSiblings.forEach((triggerSibling) => {
  //       if (
  //         e.target.closest(".single-post_info_faq_question") !== triggerSibling
  //       ) {
  //         triggerSibling.classList.remove("active");
  //       }
  //     });
  //     contents.forEach((content) => {
  //       if (
  //         e.target.closest(".single-post_info_faq_question")
  //           .nextElementSibling !== content
  //       ) {
  //         content.classList.add("hidden");
  //       } else {
  //         content.classList.toggle("hidden");
  //       }
  //     });
  //     trigger.classList.toggle("active");
  //   });
  // });
};
